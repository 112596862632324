import React, { Component } from 'react'
import SearchResultListItem from './SearchResultListItem'
import {
    connectHits,
} from 'react-instantsearch-dom'

class SearchResultList extends Component {

    render() {
        const { hits } = this.props

        return hits.length > 0 ? (
            <div className={'search-result-list'}>
                {hits.map(hit => <SearchResultListItem key={hit.slug} hit={hit}/>)}
            </div>
        ) : <p className="no-result-message">No results.</p>
    }
}

export default connectHits(SearchResultList)