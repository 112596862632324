import React, {PureComponent} from 'react'
import SearchStats from './SearchStats'
import {MenuSelect} from 'react-instantsearch-dom'

export default class SearchFilters extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            showFilters: false,
        }
    }

    toggleFilters = () => this.setState(prevState => ({
        showFilters: !prevState.showFilters
    }))

    render() {
        return (
            <div className={`filter-panel${this.state.showFilters ? ' show-filters' : ''}`}>
                <div className="d-flex justify-content-between">
                    <SearchStats />
                    <button className="filter-toggle as-link" onClick={this.toggleFilters}>Filters</button>
                </div>
                <div className={'filters  mt-3'}>
                    <SearchFilter label="Section" attribute="userSection"/>
                    <SearchFilter label="Category" attribute="categories" />
                    <SearchFilter label="Type" attribute="guidelineType" />
                </div>
            </div>
        )
    }
}

const SearchFilter = (props) => {
    const { label, attribute } = props
    return (
        <div className="filter">
            <label>{label}</label>
            <div className="select-wrapper">
                <MenuSelect attribute={attribute} />
            </div>
        </div>
    )
}