import React, { Component } from 'react'
import {
    Highlight,
    Snippet
} from 'react-instantsearch-dom'
import isArray from 'lodash/isArray'
import { buildURL, CONTENTFUL_GRAPHQL_TYPES, JSON_ENTRY_TYPES } from '../../utils/url'

export default class SearchResultListItem extends Component {

    render() {
        const { hit } = this.props
        const { healthPathways, informationForFamilies, calculators } = hit
        let type = CONTENTFUL_GRAPHQL_TYPES.GENERIC_PAGE
        let targetAttr = ''
        if (hit.type === 'GUIDELINE') {
            type = CONTENTFUL_GRAPHQL_TYPES.GUIDELINE
        } else if (hit.type === 'HEALTHPOINT') {
            type = JSON_ENTRY_TYPES.SERVICE
        } else if (hit.guidelineType && hit.guidelineType.toLowerCase() === 'calculator') {
            type = CONTENTFUL_GRAPHQL_TYPES.CALCULATOR
        }

        let url = buildURL(hit, type)
        if (hit.searchRedirectUrl) {
            url = hit.searchRedirectUrl
            targetAttr = '_blank'
        }
        return (
            <div className={'search-result-item'}>
                <div className={'title'}>
                    <h5><a target={targetAttr} href={url}><Highlight attribute={'title'} hit={hit} /></a></h5>
                    <div className={'badges'}>
                        { healthPathways && (
                            <div className="badge"><i className="fal fa-user-md icon" /></div>
                        )}
                        { informationForFamilies && (
                            <div className="badge"><i className="fal fa-notes-medical icon" /></div>
                        )}
                        { calculators && (
                            <div className="badge"><i className="fal fa-calculator icon" /></div>
                        )}
                        { this.getCategoryBadges() }
                    </div>
                </div>
                <div className={'summary'} >
                    <Snippet attribute={'content'} hit={hit} />
                </div>
            </div>
        )
    }

    getCategoryBadges() {
        const { categories } = this.props.hit

        return isArray(categories) && categories.map((category) => (
            <div key={category} className="badge badge-category">
                <span>{category}</span>
            </div>
        ))
    }
}